import React, { ReactNode } from "react";
import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/raleway";
import "@fontsource/roboto";

interface HasKids {
  children: ReactNode;
}

interface CoffeeText extends HasKids {
  className?: string;
  [rest:string]: unknown;
}

const CoffeeH2 = ({ className, children }: CoffeeText) => {
  const classList: string = className
    ? `text-coffee-green-700 font-bold text-2xl ${className}`
    : "text-coffee-green-700 font-bold text-2xl";

    return (
      <h2 className={classList} style={{ fontFamily: "Roboto" }}>{children}</h2>
    );
};

const CoffeeA = ({ className, children, ...rest}: CoffeeText) => (
  <a style={{ fontFamily: "Roboto" }} className={className} {...rest}>{children}</a>
);

const CoffeeP = ({ className, children, ...rest}: CoffeeText) => (
  <p style={{ fontFamily: "Roboto" }} className={className} {...rest}>{children}</p>
);

const CoffeeSpan = ({ className, children, ...rest}: CoffeeText) => (
  <span style={{ fontFamily: "Roboto" }} className={className} {...rest}>{children}</span>
);

const CoffeeButton = ({ className, children }: CoffeeText) => {
  const classList: string = className
    ? `bg-coffee-green-500 rounded py-3 px-6 text-white text-xl font-bold focus:ring-4 focus:ring-coffee-green-400 ${className}`
    : "bg-coffee-green-500 rounded py-3 px-6 text-white text-xl font-bold focus:ring-4 focus:ring-coffee-green-400";

  return (
    <button className={classList} style={{ fontFamily: "Roboto" }}>{children}</button>
  );
};

const Nav = () => (
  <div className="flex flex-wrap flex-col bg-white shadow-md w-full md:flex-row md:flex-nowrap md:absolute md:left-0 md:top-0 md:z-10">
    <div className="flex flex-wrap flex-col justify-left md:flex-row md:flex-nowrap md:w-5/6 md:pl-10 md:justify-between">
      <CoffeeA className="md:hidden px-5 py-2 text-2xl text-coffee-green-600 font-semibold" href="#" style={{ fontFamily: "Raleway" }}>GreenCoffeeMagic.com</CoffeeA>
      <CoffeeA className="px-5 py-2" href="#">Home</CoffeeA>
      <CoffeeA className="px-5 py-2" href="#">Buy Now</CoffeeA>
      <a className="hidden md:block px-5 py-2 text-2xl text-coffee-green-600 font-semibold" href="#" style={{ fontFamily: "Raleway" }}>GreenCoffeeMagic.com</a>
      <CoffeeA className="px-5 py-2" href="#">About Us</CoffeeA>
      <CoffeeA className="px-5 py-2" href="#">References</CoffeeA>
    </div>
    <div className="flex md:w-1/6 justify-between md:items-center md:justify-end">
      <i className="px-5 py-2 fal fa-user-alt" />
      <i className="px-5 py-2 fal fa-shopping-cart" />
    </div>
  </div>
);

const BuySection = () => (
  <div className="relative overflow-hidden">
    <div className="py-5 px-0 ml-10 md:max-w-sm md:pr-10 md:w-1/2 md:pt-24 lg:pr-0 lg:max-w-lg">
      <h1 className="text-coffee-green-600 text-5xl font-bold" style={{ fontFamily: "Raleway" }}>Green Coffee Magic</h1>
      <span className="block w-24 border-2 border-coffee-green-600 rounded mt-10" style={{ height: "2px" }} />
      <CoffeeH2 className="mt-5 md:mt-24 lg:mt-5">Buy the Green Coffee Bean Extract That Can Really Help You Lose Weight!</CoffeeH2>
      <CoffeeP className="text-coffee-green-500 mt-3">Don't fall for the cheap immitations!</CoffeeP>
      <CoffeeP className="text-xs mt-3">Most don’t have the right ingredient shown to be effective or they don’t include the right amount to help you lose weight.</CoffeeP>
      <CoffeeButton className="mt-5">Buy Now</CoffeeButton>
    </div>
    <div
      className="hidden md:block md:absolute md:bg-coffee-green-500 md:transform md:-rotate-45 md:z-0 md:rounded-lg"
      style={{ width: "1000px", height: "561px", left: "100%", top: "-200px", marginLeft: "-400px" }}
    />
    <div
      className="hidden md:block md:absolute md:z-10"
      style={{
        left: "100%",
        top: "90px",
        marginLeft: "-332px"
      }}
    >
      <StaticImage
        alt="coffee plant"
        className="rounded-full"
        layout="fixed"
        placeholder="blurred"
        src="../images/coffee-plant.jpg"
        height={300}
        width={300}
      />
    </div>
  </div>
);

const WhySection = () => (
  <div className="flex flex-wrap lg:flex-nowrap">
    <div className="hidden md:block md:mx-10 md:my-5">
      <StaticImage
        alt="green coffee beans and measuring tape"
        className="border-4 border-coffee-green-400"
        layout="fixed"
        placeholder="blurred"
        src="../images/coffee-measure.jpg"
        height={299}
        width={513}
      />
    </div>
    <div className="mx-10 mt-5 md:w-full lg:w-auto lg:mt-0">
      <span className="block w-24 border-2 border-coffee-green-600 rounded mt-10" style={{ height: "2px" }} />
      <CoffeeH2 className="mt-5">Why Do the Green Bean Extract Diet?</CoffeeH2>
      <CoffeeP className="text-coffee-green-500 mt-3">Because it actually works!</CoffeeP>
      <CoffeeP className="text-xs mt-3">Scientists conducted a clinical test to see if green coffee bean extract helped people lose weight. The scientists reported that participants lost an average of 17 pounds each and 16% body fat after taking the supplement.*</CoffeeP>
      <CoffeeP className="text-xs mt-3">The Green Coffee Bean Extract used in the study had 50% Chlorogenic Acid. They didn’t use the cheap imitators that had lower amounts of this essential ingredient.</CoffeeP>
    </div>
  </div>
);


const GetYourSupplySection = () => (
  <div className="px-10 md:px-0 mt-10 md:relative">
    <div
      className="p-5 bg-coffee-green-400 w-full md:absolute md:left-0 md:top-44 md:w-96 md:rounded-r-sm md:z-50"
      style={{
        boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgba(0,0,0,0.3), 0 1px 2px 0 rgba(0,0,0,0.3)"
      }}
    >
      <CoffeeSpan className="text-2xl text-coffee-green-700 font-bold">You Saw It on TV</CoffeeSpan>
      <CoffeeP className="font-bold text-xs mt-4">Look for a product that</CoffeeP>
      <ul className="text-xs list-disc pl-5 mt-2">
        <li>Does not include unsupported facts</li>
        <li>Is made in a registered laboratory</li>
        <li>Complies with US Pharmacopeia's quality standards</li>
      </ul>
    </div>
    <div
      className="hidden md:flex md:flex-wrap md:absolute md:top-24 lg:top-8 md:bg-coffee-green-300 md:items-center md:justify-center md:z-50"
      style={{
        borderRadius: "100%",
        left: "100%",
        marginLeft: "-350px",
        width: "227px",
        height: "158px"
      }}
    >
      <CoffeeSpan className="text-2xl font-bold text-center">30 day guanantee</CoffeeSpan>
    </div>
    <div className="flex flex-wrap justify-center w-full md:ml-auto mt-5 mr-24 border-2 border-coffee-green-400 bg-white md:w-2/3 p-5">
      <CoffeeH2 className="mt-5 w-full text-center">Get Your Supply Now!</CoffeeH2>
      <StaticImage
        alt="bag of green coffee beans"
        className="m-3"
        layout="fixed"
        placeholder="blurred"
        src="../images/coffee-bag.jpg"
        height={369}
        width={559}
      />
      <div className="flex w-full justify-center">
        <CoffeeButton className="mt-5">Add to cart</CoffeeButton>
      </div>
    </div>
  </div>
);

const ReadAboutSection = () => (
  <div className="mx-10 md:max-w-lg">
    <span className="block w-24 border-2 border-coffee-green-600 rounded mt-10" style={{ height: "2px" }} />
    <CoffeeH2 className="mt-5">Read About the Clinical Study Published in 2012</CoffeeH2>
    <CoffeeP className="text-xs mt-3">Click here for more scientific references about green coffee bean extract and weight loss.</CoffeeP>
  </div>
);

const ReferencesSection = () => (
  <div className="mx-10 break-all">
    <CoffeeH2 className="mt-5 w-full text-center">References</CoffeeH2>
    <div className="mx-auto max-w-3xl text-xs">
      <CoffeeP className="mt-3 font-bold">Chlorogenic Acid Improves High Fat Diet-Induced Hepatic Steatosis and Insulin Resistance in Mine. Pharmaceutical Research, November 2014 <a href="http://link.springer.com/article/10.1007%2Fs11095-014-1526-9" target="_blank" rel="noopener noreferrer">http://link.springer.com/article/10.1007%2Fs11095-014-1526-9</a></CoffeeP>
      <CoffeeP className="mt-3">This study, conducted upon mice by researchers at the Universtiy of Georgia, provides supporting evidence that Chlorogenic Acid, a key compound found in gree coffee extract, may prevent diet-induced obesity and obesity-related metabolic syndrome.</CoffeeP>
      <CoffeeP className="mt-3 font-bold">Inhibitory effect of green coffee bean extract on fat accumulation and body weight gain in mice. BMC Complementary and Alternative Medicine, March 17, 2006 <a href="http://www.biomedcentral.com/1472-6882/6/9 (full text)" target="_blank" rel="noopener noreferrer">http://www.biomedcentral.com/1472-6882/6/9 (full text)</a></CoffeeP>
      <CoffeeP className="mt-3">Study concluded that "GCBE can suppress body weight gain and visceral fat accumulation in mice. Caffeine suppresses fat absorption, while chlorogenic acid and its related compounds are found to be involved in the enhancement of fat metabolism in the liver."</CoffeeP>
      <CoffeeP className="mt-3 font-bold">The Use of Green Coffee Extract as a Weight Loss Supplement: A Systematic Review and Meta-Analysis of Randomised Clinical Trials. Gastroenterology Research and Practice, August 10, 2010 <a href="http://www.hindawi.com/journals/grp/2011/382852/" target="_blank" rel="noopener noreferrer">http://www.hindawi.com/journals/grp/2011/382852/</a></CoffeeP>
      <CoffeeP className="mt-3">The authors conclude that the evidence seems to indicate that "GCE can promote weight loss" but the size of the effect is small and the clinical relevance of the finding is uncertain. More rigorous trials of longer duration are needed.</CoffeeP>
    </div>
  </div>
);

const LastUpdated = () => (
  <div className="flex justify-end mt-24 mr-2">
    <CoffeeSpan className="text-xs" style={{ fontFamily: "Roboto" }}>Last updated: 2018</CoffeeSpan>
  </div>
);

const Footer = () => (
  <div className="flex flex-wrap flex-col bg-white w-full md:flex-row md:flex-nowrap mt-2">
      <CoffeeA className="px-5 py-2" href="#">Home</CoffeeA>
      <CoffeeA className="px-5 py-2" href="#">Buy Now</CoffeeA>
      <CoffeeA className="px-5 py-2" href="#">About Us</CoffeeA>
      <CoffeeA className="px-5 py-2" href="#">References</CoffeeA>
      <CoffeeA className="hidden md:block px-24 py-2 text-2xl text-coffee-green-600 font-semibold" href="#" style={{ fontFamily: "Raleway" }}>GreenCoffeeMagic.com</CoffeeA>
  </div>
);

const greenCoffeeMagic = () => {
  return (
    <div className="bg-gray-100 overflow-hidden">
      <Nav />
      <BuySection />
      <WhySection />
      <GetYourSupplySection />
      <ReadAboutSection />
      <ReferencesSection />
      <LastUpdated />
      <Footer />
    </div>
  );
};

export default greenCoffeeMagic;
